<div class="filtros">
    <mat-expansion-panel [expanded]="aberto" (expandedChange)="abrirFiltros()" class="mt-1">
        <mat-expansion-panel-header class="d-flex align-items-center">
            <mat-panel-title class="pl-2 w-100 d-flex align-items-center">
                <div class="d-flex align-items-center">
                    <span>
                        Filtros
                    </span>
                </div>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="conteudo px-2">
            <div *ngFor="let filtroSelecionado of filtrosForm.controls; let i = index;">
                <mat-form-field appearance="outline" class="w-auto">
                    <mat-label>Propriedade</mat-label>
                    <mat-select [formControl]="filtroSelecionado.get('propriedade')">
                        <mat-option *ngFor="let filtro of filtros" [value]="filtro.propriedade">
                            {{filtro.descricao}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-auto">
                    <mat-label>Condição</mat-label>
                    <mat-select [formControl]="filtroSelecionado.get('condicao')">
                        <mat-option
                            *ngFor="let condicao of condicoesFiltradas(filtroSelecionado.get('propriedade').value)"
                            [value]="condicao.valor">
                            {{condicao.descricao}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-auto"
                    *ngIf="exibirCampoPadrao(filtroSelecionado.get('propriedade').value)">
                    <mat-label>Pesquisar</mat-label>
                    <input #input type="text" matInput placeholder="Pesquisar" #inputSearch
                        [formControl]="filtroSelecionado.get('termo')" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-auto"
                    *ngIf="exibirCampoBooleano(filtroSelecionado.get('propriedade').value)">
                    <mat-label>Pesquisar</mat-label>
                    <mat-select [formControl]="filtroSelecionado.get('termo')">
                        <mat-option [value]="'True'">
                            Sim
                        </mat-option>
                        <mat-option [value]="'False'">
                            Não
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-auto"
                    *ngIf="exibirCampoData(filtroSelecionado.get('propriedade').value)">
                    <mat-label>Data</mat-label>
                    <input name="dataTermo" matInput [matDatepicker]="picker"
                        [formControl]="filtroSelecionado.get('termo')" placeholder="Ex 09/12/2023">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-auto"
                    *ngIf="exibirOptions(filtroSelecionado.get('propriedade').value)">
                    <mat-label>Pesquisar</mat-label>
                    <mat-select [formControl]="filtroSelecionado.get('termo')">
                        <mat-option *ngFor="let opcao of options(filtroSelecionado.get('propriedade').value)"
                            [value]="opcao.valor">
                            {{opcao.texto}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-stroked-button class="mr-1" color="accent" aria-label="adicionar"
                    (click)="adicionarFiltro()" *ngIf="exibirAdicionar(i)">
                    <mat-icon>add</mat-icon>
                </button>
                <button mat-stroked-button class="text-danger" aria-label="remover" (click)="removerFiltro(i)">
                    <mat-icon>delete_forever</mat-icon>
                </button>
            </div>
        </div>
    </mat-expansion-panel>
    <button mat-flat-button class="mx-1 ci-btn ci-btn-search" color="primary" aria-label="Pesquisar" (click)="submit()">
        <mat-icon>search</mat-icon>
    </button>
    <button mat-stroked-button class="ci-btn ci-btn-cancel" color="accent" aria-label="Limpar" (click)="limpaForm()">
        <mat-icon>clear</mat-icon>
    </button>
</div>