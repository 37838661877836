import {Selector} from '@ngxs/store';
import {ContaEmUsoModel} from '../conta-em-uso.model';
import {ContaEmUsoStateModel} from './conta-em-uso-state-model';
import {ContaEmUsoState} from './conta-em-uso.state';

export class ContaEmUsoSelectors {

    @Selector([ContaEmUsoState])
    public static contasFiltradas({ filtro, contas }: ContaEmUsoStateModel): ContaEmUsoModel[] {
        if (filtro && typeof (filtro) === 'string') {
            return contas!.filter(x => x.nome.toLowerCase().indexOf(filtro.toLowerCase()) > -1);
        }

        return contas!;
    }

    @Selector([ContaEmUsoState])
    public static contas({ contas }: ContaEmUsoStateModel): ContaEmUsoModel[] {
        return contas;
    }
    @Selector([ContaEmUsoState])
    public static contaEmUso({ selecionada }: ContaEmUsoStateModel): ContaEmUsoModel {
        return selecionada;
    }
}
