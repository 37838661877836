<p *ngIf="!data" class="no-data">
    Carregando...
</p>

<p *ngIf="data && !data.length" class="no-data" [innerHtml]="mensagemSemDados">
</p>

<div (window:resize)="setMaxheight($event)">
    <div class="table-overflow" [style]=" limitarAlturaMaxima ? 'max-height: ' + maxHeight + 'px' : ''">
        <table mat-table [dataSource]="data" class="mat-elevation-z1" *ngIf="data?.length" matSort (matSortChange)="sortBy($event)">
            <ng-container matColumnDef="{{column}}" *ngFor="let column of props">
                <th mat-header-cell *matHeaderCellDef [disabled]="isAction(column) || column === 'checkbox' || !getPermiteOrdenar(column)"
                    [mat-sort-header]="column" [ngClass]="{
                'w-acoes': isAction(column),
                'w-checkbox' : column === 'checkbox'
            }">
                    <mat-checkbox *ngIf="column === 'checkbox' && mostrarSelecionarTodos"
                                  (change)="selecionarTodosHandler($event.checked)" [checked]="todosSelecionados()">
                    </mat-checkbox>
                    <ng-container *ngIf="column !=='checkbox'">
                        {{isAction(column) ? getAcoesTitle() : column}}
                    </ng-container>
                </th>

                <td mat-cell *matCellDef="let item" [ngClass]="
            {
            'w-acoes': column === 'Ações',
            'w-checkbox' : column === 'checkbox'
            }" [ngStyle]="{ 'background-color': getCorLinha(item), 'color': getCorFonte(item)}">
                    <ng-container *ngIf="column !== 'Ações';else botoes">
                        <mat-checkbox *ngIf="column ==='checkbox' && mostrarSelecionarItem"
                                      (change)="emitirSelecionarItem(item, $event.checked)" [checked]="itemSelecionado(item)"></mat-checkbox>
                        <div *ngIf="column !=='checkbox'" [class]="getClasses(item, column)" [title]="getTitle(item,column) || ''">
                            <span *ngIf="hasTooltip(item, column)" class="tooltiptext">{{getToolTip(item, column)}}</span>
                            <div [ngClass]="{'multline': getMultiLinhas(column)}">
                                {{ getMask(item,column) ? (getValue(item,column) | mask: getMask(item,column)) : getValue(item,column) }}
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #botoes>
                        <button mat-icon-button *ngIf="config.actions?.length === 1 && !esconder(item, config.actions[0])"
                                (click)="config.actions[0].action(item)" [disabled]="desabilitar(item, config.actions[0])">
                            <mat-icon [color]="getCorIcone(item, config.actions[0])">{{config.actions[0].icone || "more_horiz"}}</mat-icon>
                        </button>
                        <button mat-icon-button *ngIf="config.actions?.length > 1 && !todasOpcoesEscondidas(item)"
                                [matMenuTriggerFor]="actionsMenu" [matMenuTriggerData]="{item: item, config: config}">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                    </ng-template>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="props; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: props;"></tr>
        </table>
    </div>
    <mat-paginator
            *ngIf="meta?.total"
            [pageSizeOptions]="pageSizeOptions"
            [pageSize]="meta!.qtdeRegistros"
            [pageIndex]="meta!.pagina! -1"
            [length]="meta!.total"
            (page)="handleMetaChanged($event)"
            [showFirstLastButtons]="true">
    </mat-paginator>
</div>
<mat-menu #actionsMenu="matMenu">
    <ng-template matMenuContent let-item="item">
        <div *ngFor="let action of config.actions">
            <div *ngIf="!esconder(item, action)">
                <button mat-menu-item (click)="action.action(item)" [disabled]="desabilitar(item, action)">
                    <span>{{action.title}} </span>
                </button>
            </div>
        </div>
    </ng-template>
</mat-menu>
