<form class="ml-auto d-flex flex-wrap align-items-start" [formGroup]="form" (ngSubmit)="submit()">
  <mat-form-field appearance="outline" class="w-auto" *ngFor="let filter of filtrosAdicionais">
    <mat-label>{{filter.label}}</mat-label>
    <mat-select [formControl]="getForm(filter.property)" >
      <mat-option *ngFor="let item of filter.filtro" [value]="item.value">
        {{item.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="outline" class="w-auto" *ngIf="temFiltroPrioridade">
    <mat-label>Prioridade</mat-label>
    <input #input
      type="number"
      matInput
      formControlName="prioridade"
      min="0"
      max="30"
      #inputSearch
    />
  </mat-form-field>
  <mat-form-field appearance="outline" class="w-auto">
    <mat-label>{{labelTermo}}</mat-label>
    <input #input
      type="text"
      matInput
      formControlName="termo"
      [placeholder]="placeHolder"
      #inputSearch
    />
  </mat-form-field>
  <button
   mat-flat-button
   class="mx-1 ci-btn ci-btn-search"
    color="primary"
    aria-label="Pesquisar"
  >
  <mat-icon>search</mat-icon>
  </button>
  <button
    mat-stroked-button
    class="ci-btn ci-btn-cancel"
    color="accent"
    aria-label="Limpar"
    (click)="limpaForm()"
  >
  <mat-icon>clear</mat-icon>
  </button>
</form>
