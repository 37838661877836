export const STORAGE_USUARIO_LOGADO: string = 'CatLog.UsuarioLogado';
export const STORAGE_TOKEN: string = 'CatLog.Token';
const STORAGE_PERMISSOES: string = 'CatLog.Permissoes';
const STORAGE_PORTAL_UNICO_ACCESS_TOKEN: string = 'PortalUnicoAccessToken';
const STORAGE_PORTAL_UNICO_XCSRF_TOKEN: string = 'PortalUnicoXcsrfToken';
const STORAGE_PORTAL_UNICO_XCSRF_EXPIRACAO: string = 'PortalUnicoXcsrfExpiracao';
const STORAGE_PORTAL_UNICO: string = 'PortalUnico';

export class LocalStorageUtil {

  public static obterUsuario(): string | null {
    return localStorage.getItem(STORAGE_USUARIO_LOGADO);
  };

  public static limparDadosLocaisUsuario() {
    localStorage.removeItem(STORAGE_TOKEN);
    localStorage.removeItem(STORAGE_USUARIO_LOGADO);
    this.removerDadosPortalUnico();
  };

  public static obterTokenUsuario(): string | null {
    return localStorage.getItem(STORAGE_TOKEN);
  };

  public static salvarTokenUsuario(token: string) {
    localStorage.setItem(STORAGE_TOKEN, token);
  };

  public static salvarUsuario(user: string) {
    localStorage.setItem(STORAGE_USUARIO_LOGADO, user);
  };

  public static salvarPermissoes(permissoes: string) {
    localStorage.setItem(STORAGE_PERMISSOES, permissoes);
  }

  public static obterPermissoes(): string {
    return localStorage.getItem(STORAGE_PERMISSOES);
  };

  public static obterDadosPortalUnico = () => ({
    accessToken: sessionStorage.getItem(STORAGE_PORTAL_UNICO_ACCESS_TOKEN),
    xCSRFToken: sessionStorage.getItem(STORAGE_PORTAL_UNICO_XCSRF_TOKEN),
    xCSRFExpiracao: sessionStorage.getItem(STORAGE_PORTAL_UNICO_XCSRF_EXPIRACAO)
  });

  public static salvarDadosPortalUnico({ setToken: accessToken, xcsrfToken, xcsrfExpiration: tempoExpiracao }:
    { setToken: string; xcsrfToken: string; xcsrfExpiration: string }) {
    sessionStorage.setItem(STORAGE_PORTAL_UNICO_ACCESS_TOKEN, accessToken);
    LocalStorageUtil.atualizarXcsrfToken(xcsrfToken, tempoExpiracao);
  };

  public static atualizarXcsrfToken(xcsrfToken: string, xcsrfExpiration: string) {
    sessionStorage.setItem(STORAGE_PORTAL_UNICO_XCSRF_TOKEN, xcsrfToken);
    sessionStorage.setItem(STORAGE_PORTAL_UNICO_XCSRF_EXPIRACAO, xcsrfExpiration);
  };

  public static removerDadosPortalUnico = () => {
    sessionStorage.removeItem(STORAGE_PORTAL_UNICO_ACCESS_TOKEN);
    sessionStorage.removeItem(STORAGE_PORTAL_UNICO_XCSRF_TOKEN);
    sessionStorage.removeItem(STORAGE_PORTAL_UNICO_XCSRF_EXPIRACAO);
  };
}
