import { DOCUMENT } from '@angular/common';
import { Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser'
import { ApiResult, LoginService, MasterService, UsuarioToken } from 'ui-shared';
import { masterConfiguracao, masterOperacao, masterSemContaEmUso } from './master-config';
import { Subscription } from 'rxjs';
import { Rules } from './features/compartilhado/roles-guard/rules';
import { ConfiguracaoDataService } from './services/data-services/configuracao.data.service';
import { ConfiguracaoEntity } from './models/configuracao.entity';

@Component({
    selector: 'app-root',
    template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit, OnDestroy {
    token: UsuarioToken;
    subscriptions: Subscription[] = [];

    constructor(
        private router: Router,
        private loginService: LoginService,
        private masterService: MasterService,
        private configuracaoDataService: ConfiguracaoDataService,
        private ngZone: NgZone,
        @Inject(DOCUMENT) private document: Document,
        private title: Title,
        private rules: Rules
    ) {
        this.title.setTitle('Catálogo de Produtos')
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach(x => x.unsubscribe());
    }

    ngOnInit(): void {
        this.subscriptions.push(this.loginService.token$.subscribe(token => {
            if (token) {
                this.ngZone.run(() => {
                    if (token.contaEmUso) {
                        let ambiente: string;
                        
                        this.subscriptions.push(this.configuracaoDataService.obterAmbiente()
                            .subscribe(({ data }: ApiResult<ConfiguracaoEntity>) => {
                                ambiente = data.ambiente != "Produção" ? `Ambiente ${data.ambiente}` : "";

                                this.masterService.update(masterOperacao(this.rules, this.loginService.accessToken, ambiente));
                                this.router.events.subscribe((event) => {
                                    if (event instanceof NavigationEnd) {
                                        if (event.url === '/') {
                                            this.router.navigateByUrl('/home');
                                        }
                                    }
                                });
                            }));
                        
                        this.subscriptions.push(this.configuracaoDataService.obterIconeDespachante()
                            .subscribe(({data} : ApiResult<string>) => {
                                this.masterService.update(masterOperacao(this.rules, this.loginService.accessToken, ambiente, data))
                            }));
                    } else if (token.suporte) {
                        this.masterService.update(masterConfiguracao);
                    } else {
                        this.masterService.update(masterSemContaEmUso);
                    }
                });
            }
        }));
    }
}
