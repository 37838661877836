import {
    EnumTipoCertificadoEnvioPortalUnico
} from "@catalogo/features/compartilhado/enums/enum-parametro-configuracao";

export class DadosAutenticacaoPortalUnico
{
    public constructor(
        public accessToken: string,
        public xCSRFToken: string,
        public xCSRFExpiracao: string,
        public tipoCertificado: EnumTipoCertificadoEnvioPortalUnico
    ) { }
}
