import {Injectable} from '@angular/core';
import {Dispatch} from '@ngxs-labs/dispatch-decorator';
import {FecharModalRemover} from '../usuario-certificado-store/usuario-certificado.actions';

@Injectable({ providedIn: 'root' })
export class UsuarioCertificadoRemoverService {

    constructor() { }

    @Dispatch()
    public cancelarRemover = () => new FecharModalRemover({ confirmacao: false });

    @Dispatch()
    public confirmarRemover = () => new FecharModalRemover({ confirmacao: true });

}
