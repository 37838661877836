<mat-dialog-content class="mat-typography">
    <h3>
        {{data.mensagem}}
    </h3>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button
        mat-flat-button
        [color]="data.corNao ? data.corNao : null"
        [mat-dialog-close]="true"
        (click)="data.cancelar(data.entity)"
    >
        Não
    </button>
    <button
        mat-flat-button
        [color]="data.corSim ? data.corSim : 'primary'"
        [mat-dialog-close]="true"
        (click)="data.confirmar(data.entity)"
    >
        Sim
    </button>
</mat-dialog-actions>
