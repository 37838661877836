import {Injectable} from '@angular/core';
import {FecharModalRemover} from '../usuario-certificado-store/usuario-certificado.actions';
import { Store } from "@ngxs/store";

@Injectable({ providedIn: 'root' })
export class UsuarioCertificadoRemoverService {

    constructor(private store: Store) { }
    
    public cancelarRemover = () => this.store.dispatch(new FecharModalRemover({ confirmacao: false }));
    public confirmarRemover = () => this.store.dispatch(new FecharModalRemover({ confirmacao: true }));
}
