import { Component, Input, } from '@angular/core';
import { LoginService } from '../../services';
import {Link} from "../models";

@Component({
  selector: 'ui-usuario-logado',
  templateUrl: './usuario-logado.component.html',
})
export class LoginHomeComponent {

  @Input() links!: Link[];

  constructor(
    public service: LoginService,
  ) {
  }

  naoFecharMenu = (e: any) => e.stopPropagation();
}


