import { Injectable } from '@angular/core';
import { LocalStorageUtil, UsuarioAutenticado, UsuarioPermissoes } from "ui-shared";

@Injectable({
    providedIn: 'root'
})
export class Rules {
    private permissoes: UsuarioPermissoes;
    private usuarioLogado: UsuarioAutenticado;

    constructor() {
        this.permissoes = JSON.parse(LocalStorageUtil.obterPermissoes());
        this.usuarioLogado = JSON.parse(LocalStorageUtil.obterUsuario());
    }

    public possuiPermissao(role: string, exigeEdicao: boolean = false): boolean {
        if (role == EnumRoles.Suporte && this.usuarioLogado && !this.usuarioLogado.suporte) {
            return false;
        }

        if (role != EnumRoles.Multiempresa && this.usuarioLogado && (this.usuarioLogado.suporte || this.usuarioLogado.claims.find(claim => claim.type == "administrador")?.value == "True")) {
            return true;
        }

        if (!this.permissoes) {
            return false;
        }

        const possuiPerfilAdministrador = this.permissoes.perfis.some(perfil => perfil.perfilAcesso == EnumRoles.Administrador);
        const possuiPerfil = this.permissoes.perfis.some(perfil => {
            let temEdicao = true;

            if (exigeEdicao) {
                temEdicao = perfil.permiteEdicao;
            }
            return perfil.perfilAcesso == role && temEdicao;
        });
        
        if(role == EnumRoles.Multiempresa){
            return possuiPerfil;
        }

        return this.permissoes.administrador || possuiPerfilAdministrador || possuiPerfil;
    };
}

export enum EnumRoles {
    Suporte = 'Suporte',
    Administrador = 'Administrador',
    CadastroOperacional = 'CadastroOperacional',
    CadastroPdm = 'CadastroPdm',
    EnvioPortalUnico = 'EnvioPortalUnico',
    RetificadorProduto = 'RetificadorProduto',
    SaneamentoAnalista = 'SaneamentoAnalista',
    SaneamentoAuditor = 'SaneamentoAuditor',
    Multiempresa = 'Multiempresa',
    VinculacaoFabricante = 'VinculacaoFabricante',
    SaneamentoDetalhamento = 'SaneamentoDetalhamento'
}

export enum EnumCondicional {
    PossuiAlguma = 1,
    PossuiTodas = 2
}
