import {SortDirection} from "@angular/material/sort";

export interface QueryParams {
  [name: string]: string | string[];
}

export class Meta {

  public static padrao = {
    filtro: '',
    pagina: 1,
    qtdeRegistros: 10,
    ordenacao: '',
    sentido: 'asc',
    total: undefined,
    parentId: undefined,
    ativo: true
  } as Meta;

  filtro?: string = '';
  pagina?: number = 1;
  qtdeRegistros?: number = 10;
  ordenacao?: string = 'id';
  sentido?: SortDirection = 'asc';
  total?: number = 0;
  parentId?: string;
  ativo?: boolean;
}

