import { EnumTipoCertificadoEnvioPortalUnico } from "../../enums/enum-parametro-configuracao";

export class Enviar {
  public static readonly type = '[UsuarioCertificado] Enviar Certificado';
  public constructor(public payload: any) { }
}

export class Existe {
  public static readonly type = '[UsuarioCertificado] Existe Certificado';
}

export class Validar {
  public static readonly type = '[UsuarioCertificado] Validar Certificado';
  public constructor(public payload: string) { }
}

export class Logar {
  public static readonly type = '[UsuarioCertificado] Logar portal único';
  public constructor(public payload:{ certificadoPadrao: boolean, senha?: string}) { }
}

export class AbrirModalRemover {
  public static readonly type = '[UsuarioCertificado] abrir modal remover';
}

export class FecharModalRemover {
  public static readonly type = '[UsuarioCertificado] fechar modal remover';
  public constructor(public payload: { confirmacao: boolean }) { }
}
export class Remover {
  public static readonly type = '[UsuarioCertificado] remover';
}

export class AbrirModalSenha {
  public static readonly type = '[UsuarioCertificado] Abrir Modal';
  public constructor(public logar: boolean, public solicitarSenha: boolean = true) { }
}

export class FecharModalSenha {
  public static readonly type = '[UsuarioCertificado] Fechar Modal';
  public constructor(public payload: { validar: boolean; senha?: string, logar?: boolean, tipoCertificado?: EnumTipoCertificadoEnvioPortalUnico }) { }
}
