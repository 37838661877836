import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MasterComponent} from './master';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {LoaderComponent} from './loader';
import {MatButtonModule} from '@angular/material/button';
import {RouterModule} from '@angular/router';
import {LoginHomeComponent} from './usuario-logado';
import {MatSelectModule} from '@angular/material/select';
import {ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {ContaEmUsoComponent, ContaEmUsoState} from './conta-em-uso';
import {NgxsModule} from '@ngxs/store';
import {NgxsFormPluginModule} from '@ngxs/form-plugin';
import {MatMenuModule} from '@angular/material/menu';
import {SearchModule} from '../search';
import {MatExpansionModule} from '@angular/material/expansion';
import {MasterItemComponent} from './master/master-item/master-item.component';
import {MatDividerModule} from '@angular/material/divider';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatBadgeModule} from '@angular/material/badge';
import {NotificacaoComponent, NotificacaoState} from './notificacao';

export const MASTER_OPTIONS = 'MASTER_OPTIONS';

@NgModule({
    declarations: [
        MasterComponent,
        LoaderComponent,
        LoginHomeComponent,
        ContaEmUsoComponent,
        MasterItemComponent,
        NotificacaoComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        MatIconModule,
        MatButtonModule,
        MatMenuModule,
        MatToolbarModule,
        MatSidenavModule,
        MatListModule,
        MatInputModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatExpansionModule,
        MatDividerModule,
        MatBadgeModule,
        NgxsModule.forFeature([ContaEmUsoState, NotificacaoState]),
        NgxsFormPluginModule,
        SearchModule
    ],
    exports: [MasterComponent]
})
export class MasterModule { }
