import {Inject, Injectable} from '@angular/core';
import {MASTER_DEFAULT_OPTIONS, MasterConfig} from './master-config';
import {Link} from '../models';
import {ContaEmUsoModel} from "../conta-em-uso";

@Injectable({ providedIn: 'root' })
export class MasterService {

    constructor(
        @Inject(MASTER_DEFAULT_OPTIONS) private config: MasterConfig
    ) { }

    get links(): Link[] {
        return this.config.links;
    }

    get titulo(): string {
        return this.config.titulo;
    }

    get notificacao(): boolean {
        return this.config.notificacao;
    }

    get multiConta(): boolean {
        return this.config.multiConta;
    }

    get permicaoAmbiente(): string {
        return this.config.permicaoAmbiente;
    }

    get contas(): ContaEmUsoModel[] {
        return this.config.contas;
    }

    get rota(): string {
        return this.config.rota;
    }

    get usuarioLinks() {
        return this.config.usuarioLinks;
    }

    get imgURL(): string {
        return this.config.imgUrl;
    }

    get ambiente(): string {
        return this.config.ambiente;
    }

    get iconeDespachante(): string {
        return this.config.iconeDespachante;
    }

    public update(config: MasterConfig) {
        this.config.links = config.links;
        this.config.titulo = config.titulo;
        this.config.rota = config.rota;
        this.config.usuarioLinks = config.usuarioLinks;
        this.config.notificacao = config.notificacao;
        this.config.multiConta = config.multiConta;
        this.config.permicaoAmbiente = config.permicaoAmbiente;
        this.config.contas = config.contas;
        this.config.imgUrl = config.imgUrl;
        this.config.ambiente = config.ambiente;
        this.config.iconeDespachante = config.iconeDespachante;
    }
}
