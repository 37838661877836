import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Subscription} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';
import {ContaEmUsoService} from './conta-em-uso.service';
import {ContaEmUsoModel} from "./conta-em-uso.model";

@Component({
    selector: 'ui-conta-em-uso',
    templateUrl: './conta-em-uso.component.html',
    styleUrls: ['./conta-em-uso.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class ContaEmUsoComponent implements OnInit, OnDestroy {

    @Input() public contas: ContaEmUsoModel[];
    @Input() public permicaoAmbiente: string;

    public contaForm = new FormControl('');
    private subscription!: Subscription;

    constructor(
        public service: ContaEmUsoService,
    ) { }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    ngOnInit(): void {
        if(this.contas && this.contas.length > 0){
            this.service.atualizarContas(this.contas);
        }else{
            this.service.obterContas(this.permicaoAmbiente);
        }

        this.subscription = this.contaForm.valueChanges.pipe(
            debounceTime(500),
            map(x => this.service.filtrar(x))).subscribe();
    }

    naoFecharMenu = (e: any) => e.stopPropagation();

}
