<mat-dialog-content class="mat-typography">
  <h3>
    Você tem certeza que deseja remover o certificado?
  </h3>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-button
    [mat-dialog-close]="true"
    (click)="service.cancelarRemover()"
  >
    Não
  </button>
  <button
    mat-flat-button
    color="warn"
    [mat-dialog-close]="true"
    (click)="service.confirmarRemover()"
  >
    Sim
  </button>
</mat-dialog-actions>
