import { BaseField } from '../base-field';

export class ImgFileField<T = any> extends BaseField<T> {
    tiposAceitos: string[] = [];
    btnTexto = 'Selecione';
    prefixo = 'insert_photo';
    sufixo = 'preview';

    constructor(init: Partial<ImgFileField<T>>) {
        super('ImgFile', init);
        Object.assign(this, init);
        if (!init.css) {
            this.css = 'col-4';
        }
    }
}
