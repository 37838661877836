import {ValidatorFn} from '@angular/forms';

export abstract class BaseField<T = any> {
    public label: string = '';
    public property: string = '';
    public value!: (x: T) => any;
    public css: string = '';
    public validations: ValidatorFn[] = [];
    public placeholder: string = '';
    public disabled: boolean | ((value: T) => boolean) = false;
    public esconder: boolean | ((value: T) => boolean) = false;
    public id: string = '';

    constructor(
        public type: 'Text' | 'Hidden' | 'TextArea' | 'Checkbox' | 'Boolean' | 'Autocomplete' | 'Enum' | 'ImgFile' | 'File' | 'Date' | 'Select',
        init: Partial<BaseField>) {
        Object.assign(this, init);
    }
}
