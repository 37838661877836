import { Injectable } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ObterPendentes, Remover } from './notificacao-store/notificacao.actions';
import { NotificacaoSelectors } from './notificacao-store/notificacao.selectors';
import { NotificacaoModel } from './notificacao.model';
import { Router } from '@angular/router';
import { TipoNotificacao } from './notificacao.component';

@Injectable({
    providedIn: 'root'
})
export class NotificacaoService {

    @Select(NotificacaoSelectors.notificacoes) public notificacoes$: Observable<NotificacaoModel[]>;

    constructor(private router: Router) {
        this.obterPendentes();
    }

    @Dispatch()
    public obterPendentes = () => new ObterPendentes();

    @Dispatch()
    public remover = (tipo: TipoNotificacao) => new Remover(tipo);

    // #TODO - Refatorar esse componente de forma genérica e deixar a regra de negócio no Back
    public navegarPara(tipo: string) {
        switch (tipo) {
            case TipoNotificacao.MudancaAtributoRfb:
                return this.router.navigate(['/atributos-modificados']);
            case TipoNotificacao.ProcessamentoImportacaoProdutos:
                return this.router.navigate(['/importacao-dados']);
            case TipoNotificacao.ProcessamentoImportacaoOperadores:
                return this.router.navigate(['/importacao-dados']);
            case TipoNotificacao.ProcessamentoSugestaoPreenchimentoSaneamento:
                return this.router.navigate(['/saneamento/saneamento-atributo']);
            default:
                break;
        }
        return;
    }
}
