export const TEXT = 'Text';
export const NUMBER = 'Number';
export const DATE = 'Date';
export const BOOLEAN = 'Boolean';
export const ENUM = 'Enum';
export const OPTIONS = 'Options';
export const GUID = 'Guid';

export const CONTEM = 'Contains';
export const NAO_CONTEM = '!Contains';
export const IGUAL = '==';
export const DIFERENTE = '!=';
export const MAIOR = '>';
export const MENOR = '<';
export const MAIOR_IGUAL = '>=';
export const MENOR_IGUAL = '<=';
export const FILTRO_PLACE_HOLDER = '|FILTRO|';
