import { FocusMonitor } from '@angular/cdk/a11y';
import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { BaseFieldDirective } from '../base-field.directive';
import { HiddenField } from './hidden-field';

@Component({
  selector: 'ui-hidden-field',
  templateUrl: './hidden-field.component.html',
  styleUrls: ['./hidden-field.component.scss']
})
export class HiddenFieldComponent extends BaseFieldDirective {

  @Input() public config!: HiddenField;

  constructor(protected fmRef: FocusMonitor, protected elRef: ElementRef<HTMLElement>) {
    super(fmRef, elRef);
  }
}
