<mat-form-field
        appearance="outline"
        [formGroup]="formGroup"
        style="margin-bottom: -1.25em; width: 100%"
>
    <mat-label>{{ config.label }}</mat-label>
    <div>
        <mat-select
                [formControl]="controle"
                (selectionChange)="atualizaControle($event)"
                matTooltip="{{ getToolTip(controle.value) }}"
        >
            <mat-option>Selecione</mat-option>
            <input
                    matInput
                    type="text"
                    placeholder="Filtro"
                    (keyup)="filtrarValores($event.target.value)"
                    style="padding: 16px; width: 94%; font-size: inherit"
            />

            <mat-option
                    *ngFor="let option of listaFiltrada"
                    [value]="option.value"
                    matTooltip="{{ option.text }}"
            >
                {{ option.text }}
            </mat-option>
        </mat-select>
    </div>
</mat-form-field>