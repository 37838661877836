<form (ngSubmit)="submitForm()" [formGroup]="formGroup">
    <div #ref class="form-especifico">
        <ng-content>
        </ng-content>
    </div>
    <ng-container *ngIf="!ref.hasChildNodes()">
        <div class="row form-dinamico">
            <ui-form-field-renderer *ngFor="let field of config.fields"
                                    class="{{field.css}} {{ field.type ==='Checkbox' ? 'd-flex align-items-center': ''}}" [field]="field"
                                    [formGroup]="formGroup" [entity]="entity">
            </ui-form-field-renderer>
        </div>
    </ng-container>
    <div class="row" *ngIf="showBtn">
        <div class="col-sm-12 col-3">
            <button [hidden]="formGroup.disabled" type="submit" mat-flat-button color="accent"
                    [disabled]="disableBtn && (formGroup.invalid || (!formGroup.dirty  && !formGroup.touched))"
                    [innerHTML]="btnText || 'Adicionar'"></button>
        </div>
    </div>
</form>
