<mat-form-field
    appearance="outline"
    [formGroup]="formGroup"
    style="margin-bottom: -1.25em; width: 100%;"
>
    <mat-label>{{config?.label}}{{requerido ? ' *' : ''}}</mat-label>
    <mat-chip-list #chipList>
        <mat-chip *ngIf="selecionado">
            {{getValue()}}
            <button
                mat-icon-button
                type="button"
                matSuffix
                (click)="limpar($event)"
                *ngIf="!controle.disabled">
                <mat-icon>clear</mat-icon>
            </button>
        </mat-chip>
        <mat-progress-spinner
            matSuffix
            *ngIf="carregando && controle.dirty"
            diameter="20"
            mode="indeterminate"
            color="accent"
        ></mat-progress-spinner>
        <input
            #searchInput
            type="text"
            [placeholder]="config.placeholder"
            matInput
            [formControl]="getForm()"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            (keydown)="onBackspace($event)"
            [disabled]="selecionado !== null"
        />
    </mat-chip-list>
    <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="atualizaControle($event)"
    >
        <mat-option *ngFor="let option of values" [value]="option">
            {{option.nome}}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
