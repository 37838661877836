import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from "@angular/material/snack-bar";

@Component({
  selector: 'ui-snack-bar-multi-line',
  templateUrl: './snack-bar-multi-line.component.html',
  styleUrls: ['./snack-bar-multi-line.component.css']
})
export class SnackBarMultiLineComponent implements OnInit {
  public dados: any;
  public alertType: string;

  constructor(private snackBarRef: MatSnackBarRef<SnackBarMultiLineComponent>,
    @Inject(MAT_SNACK_BAR_DATA) data: any) {
    this.dados = data.message;
    this.alertType = data.alertType;
  }

  ngOnInit(): void {
  }

  dismiss(event: Event): void {
    this.snackBarRef.dismiss();
    event.preventDefault();
  }

}
