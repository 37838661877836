import {Injectable} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {ContaEmUsoModel} from './conta-em-uso.model';
import * as actions from './conta-em-uso-store/conta-em-uso.actions';
import {ContaEmUsoSelectors} from './conta-em-uso-store/conta-em-uso.selectors';

@Injectable({
    providedIn: 'root'
})

export class ContaEmUsoService {

    @Select(ContaEmUsoSelectors.contasFiltradas) public contasFiltradas$!: Observable<ContaEmUsoModel[]>;
    @Select(ContaEmUsoSelectors.contas) public contas$!: Observable<ContaEmUsoModel[]>;
    @Select(ContaEmUsoSelectors.contaEmUso) public conta$!: Observable<ContaEmUsoModel>;

    constructor(private store: Store) {
    }

    public obterContas = (permicaoAmbiente: string) => this.store.dispatch(new actions.ObterContas(permicaoAmbiente));

    public atualizarContas = (contas: ContaEmUsoModel[]) => this.store.dispatch(new actions.AtualizarContas(contas));

    public filtrar = (filtro: string) => this.store.dispatch(new actions.FiltrarContas(filtro));

    public selecionaConta = (conta: ContaEmUsoModel) => this.store.dispatch(new actions.SelecionarConta(conta));

    //displayFn = (item: ContaEmUsoModel) => (item.nome);
}
