<div *ngIf="service.notificacoes$ | async as notificacoes">

    <button type="button" aria-label="Toggle sidenav" mat-icon-button *ngIf="notificacoes?.length == 0">
        <mat-icon aria-label="Side nav toggle icon ">notifications</mat-icon>
    </button>

    <button type="button" aria-label="Toggle sidenav" mat-icon-button *ngIf="notificacoes.length > 0"
        [matMenuTriggerFor]="notificacao">
        <mat-icon aria-label="Side nav toggle icon " matBadge="{{notificacoes.length}}"
            matBadgeColor="warn">notifications</mat-icon>
    </button>
    <mat-menu #notificacao="matMenu" style="display: flex; flex-direction: column;">
        <div mat-menu-item style="font-size: 0.9em; font-weight: 700; text-align: center;">
            <p>Notificações</p>
        </div>
        <div mat-menu-item *ngFor="let item of notificacoes" class="itens-menu-notificacao">
            <div (click)="service.navegarPara(item.tipo)">
                {{obterMensagemTipo(item.tipo)}}
            </div>
            <i class="bi bi-x" (click)="service.remover(item.tipo)"></i>
        </div>
    </mat-menu>
</div>
