import { FocusMonitor } from '@angular/cdk/a11y';
import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit } from '@angular/core';
import { BaseFieldDirective } from '../base-field.directive';
import { SelectField, SelectFieldItem } from './select-field';

@Component({
    selector: 'ui-select-field',
    templateUrl: './select-field.component.html',
    styleUrls: ['./select-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectFieldComponent extends BaseFieldDirective implements OnInit {
    @Input() public config!: SelectField;
    
    public listaFiltrada: SelectFieldItem[] = [];
    public selecionado: string = null;
    
    constructor(
        protected fmRef: FocusMonitor,
        protected elRef: ElementRef<HTMLElement>) {
        super(fmRef, elRef);
    };
    
    ngOnInit(): void {
        if (this.config.useAsync) {
            this.subscriptions.push(this.config.valuesAsync?.subscribe((x) => {
                this.config.values = x;
                this.listaFiltrada = x;
            }));
        } else {
            this.listaFiltrada = this.config.values || [];
        }
        
        if (this.config.useValueSelected) {
            this.subscriptions.push(this.config.valueSelected.subscribe((x) => {
                this.selecionado = x?.id || null;
                this.controle.setValue(this.selecionado);
            }));
        } else {
            this.selecionado = this.controle.value || null;
        }
    }
    
    public atualizaControle = (event: any) => {
        if (this.config!.onValueSelectedFunc) {
            this.config!.onValueSelectedFunc(event.value);
        }
    };
    
    getToolTip(data: string) {
        if (data) {
            const item = this.listaFiltrada?.find(x => x.id == data);
            return item ? item.text : '';
        }
    };
    
    filtrarValores(val: string) {
        this.listaFiltrada = this.config.values?.filter(x => this.filtro(x, val)) || [];
    };
    
    filtro(item: SelectFieldItem, value: string) {
        return item.text.toLowerCase().indexOf(value.toLowerCase()) > -1;
    };
}

