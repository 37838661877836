import {Injectable, NgZone} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {map} from 'rxjs/operators';
import {ApiResult, LoginService, NotificationService} from '../../../services';
import {NotificacaoStateModel} from './notificacao-state-model';
import {ObterPendentes, Remover} from './notificacao.actions';
import {NotificacaoModel} from '../notificacao.model';
import {NotificacaoDataService} from '../notificacao.data-service';

@Injectable()
@State<NotificacaoStateModel>({
    name: 'Notificacao',
})

export class NotificacaoState {
    constructor(
        private dataService: NotificacaoDataService,
        private loginService: LoginService,
        private notification: NotificationService,
        private zone: NgZone,
    ) {
    }

    @Action(ObterPendentes)
    public obterPendentes({ patchState }: StateContext<NotificacaoStateModel>, { }: ObterPendentes) {

        if (!this.loginService.contaEmUso) {
            return;
        }

        return this.dataService.obterPendentes()
            .pipe(
                map(({ data }: ApiResult<NotificacaoModel[]>) => {
                        patchState({
                            notificacoes: data
                        })
                    }
                ));
    }

    @Action(Remover)
    public remover({ dispatch }: StateContext<NotificacaoStateModel>, { tipo }: Remover) {
        return this.dataService.remover(tipo)
            .pipe(
                map(() => {
                    dispatch(new ObterPendentes());
                }));
    }
}
