import { IntegerHelper } from "./integer.helper";
import { isNotNullOrUndefined } from "./helpers";
import { Base64Helper } from "./base64.helper";

export class StringHelper {

    public static replaceAll(str: string, find: string, replace: string) {
        function escapeRegExp(string) {
            return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& significa toda a string correspondente
        }

        return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
    }

    public static filtroTranslate(str: string) {
        return `||${String.fromCharCode(IntegerHelper.randomInteger(97, 122))}${Base64Helper.base64Encode(str)}${String.fromCharCode(IntegerHelper.randomInteger(97, 122))}||`;
    }

    public static formataCpf(cpf: string): string {
        let res = cpf

        if (cpf.length === 11) {
            res = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
        }

        return res;
    }

    public static formataCnpj(cnpj: string): string {
        let res = cnpj

        if (cnpj.length === 14) {
            res = cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
        }

        return res;
    }

    public static formatoCPF_CNPJ(str: string): string {
        let res = str;

        if (!isNotNullOrUndefined(str)) {
            return '';
        }

        if (str.length === 11) {
            return this.formataCpf(str);
        } else if (str.length === 14) {
            return this.formataCnpj(str);
        }

        return res;
    }

    public static formataTamanho(str: string, limite: number) {
        return str && str.length > limite ? str.substr(0, limite) + '...' : str;
    }

    public static toBoolean(string: string) {
        if (string == null) {
            return null;
        } else if (string?.toLowerCase() == "true") {
            return true;
        } else {
            return false
        }
    }
}
