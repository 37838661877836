import { MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MatSnackBarConfig } from '@angular/material/snack-bar';

export const matSnackbar = () => ({
  duration: 5000,
  horizontalPosition: 'right',
  verticalPosition: 'top'
} as MatSnackBarConfig);

export const matFormField = () => ({
  appearance: 'outline',
  floatLabel: 'always',
  hideRequiredMarker: false
} as MatFormFieldDefaultOptions);
