import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from './login.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from './notification.service';

@Injectable({ providedIn: 'root' })
export class ErrorHttpInterceptor implements HttpInterceptor {
    
    constructor(
        private notification: NotificationService,
        private ngZone: NgZone,
        private router: Router,
        private loginService: LoginService
    ) { }
    
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next
            .handle(req)
            .pipe(
                catchError(retorno => {
                    if (retorno instanceof HttpErrorResponse) {
                        this.ngZone.run(() => {
                            if (retorno.status === 401) {
                                this.loginService.logout();
                            }
                            else if(retorno.error){
                                if (retorno.error.erros instanceof Array) {
                                    this.notification.openErrorMultiLine(retorno.error.erros);
                                }
                                else if (retorno.error instanceof ProgressEvent) {
                                    this.notification.openError('Não foi possível completar a ação, por favor tente novamente mais tarde.');
                                }
                                else if (typeof (retorno.error) === 'string') {
                                    const erroStr = retorno.error.length > 200 ? retorno.error.substring(0, 200) : retorno.error
                                    this.notification.openError(erroStr);
                                }
                            }
                            else{
                                this.notification.openError('Não foi possível completar a ação, por favor tente novamente mais tarde.');
                            }
                        });
                    }
                    throw retorno;
                })
            );
    }
}
