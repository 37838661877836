import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ui-button-secondary',
  templateUrl: './button-secondary.component.html',
  styleUrls: ['./button-secondary.component.scss']
})
export class ButtonSecondaryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
