import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, ChangeDetectorRef, Output, OnDestroy, Input, AfterViewInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';

@Component({
  selector: 'ui-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchComponent implements OnInit, OnDestroy {
  @Output() public submitEvent = new EventEmitter();
  @Input() public placeHolder = 'Pesquisar';
  @Input() public filtro = '';
  termo!: FormControl;

  subscriptions: Subscription[] = [];

  constructor(
    private changeDetection: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.termo = new FormControl(this.filtro || '');
    this.subscriptions.push(this.termo.valueChanges
      .pipe(debounceTime(500), tap(() => this.changeDetection.markForCheck()))
      .subscribe(x => {
        this.submitEvent.emit(x);
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }
}
