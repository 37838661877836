import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {ModalConfirmarData} from "./modal-confirmar-data";

@Component({
    templateUrl: './modal-confirmar.component.html',
    styleUrls: ['./modal-confirmar.component.scss']
})
export class ModalConfirmarComponent {
    public data: ModalConfirmarData;

    constructor(
        @Inject(MAT_DIALOG_DATA) private dataDialog: ModalConfirmarData
    ) {
        this.data = dataDialog;
    }
}
