import { NgxsConfig } from '@ngxs/store/src/symbols';
import { NgxsDevtoolsOptions } from '@ngxs/devtools-plugin/src/symbols';
import { NgxsLoggerPluginOptions } from '@ngxs/logger-plugin/src/symbols';
import { environment } from 'environments/environment';
import { UsuarioCertificadoState } from '@catalogo/features/compartilhado/usuario-certificado/usuario-certificado-store/usuario-certificado.state';

export const STATES_MODULES = [
  UsuarioCertificadoState
];

export const OPTIONS_CONFIG: Partial<NgxsConfig> = {
  developmentMode: !environment.production,
  selectorOptions: {}
};

export const DEVTOOLS_REDUX_CONFIG: NgxsDevtoolsOptions = {
  disabled: environment.production
};

export const LOGGER_CONFIG: NgxsLoggerPluginOptions = {
  disabled: environment.production
};
