import { EnumAmbiente } from "@env/enum-ambiente";

export const environment = {
    permissaoAmbiente: 'Teste',
    ambiente: EnumAmbiente.Desenvolvimento,
    identidade: {
        clientId: 'identidade',
        apiUrl: 'https://identidadeapi-dev.blueroute.com.br/api',
        login: 'https://identidade-dev.blueroute.com.br/login'
    },
    catalogoConfiguracao: {
        clientId: 'cat-conf',
        apiUrl: 'https://catalogoconfapi-dev.blueroute.com.br/api'
    },
    catalogoOperacao: {
        clientId: 'cat-prod',
        apiUrl: 'https://catalogoopeapi-dev.blueroute.com.br/api',
        autenticacaoPortalUnico: '',
        treinamento: 'https://treinamento-dev.blueroute.com.br'
    },
    treinamento: {
        clientId: 'treinamento',
        apiUrl: 'https://treinamentoapi-dev.blueroute.com.br/api',
    },
    processadorSolicitacao: {
        apiUrl: 'https://catalogosolapi-dev.blueroute.com.br/api',
    },
    recaptcha: {
        siteKey: '6LfDi_IgAAAAAOddYSl2qoFIbfeEuaU7bPV8rWAo',
    }
};
