import { Action, State, StateContext } from '@ngxs/store';
import { Injectable, NgZone, } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import * as actions from './usuario-certificado.actions';
import { UsuarioCertificadoDataService } from '@catalogo/services/data-services/usuario-certificado.data.service';
import { HttpEventType } from '@angular/common/http';
import { UsuarioCertificadoStateModel } from './usuario-certificado-state.model';
import { NotificationService, LocalStorageUtil } from 'ui-shared';
import {
    UsuarioCertificadoModalRemoverComponent
} from '../usuario-certificado-modal-remover/usuario-certificado-modal-remover.component';
import {
    UsuarioCertificadoModalSenhaComponent
} from '../usuario-certificado-modal-senha/usuario-certificado-modal-senha.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EnumTipoCertificadoEnvioPortalUnico } from '../../enums/enum-parametro-configuracao';

@State<UsuarioCertificadoStateModel>({
    name: 'CertificadoUsuario',
    defaults: {
        existeUsuario: false,
        existePadrao: false,
        modalSenha: null,
        modalSenhaConfirmado: false,
        modalRemover: null
    }
})
@Injectable({ providedIn: 'root' })
export class UsuarioCertificadoState {

    constructor(
        private certificadoService: UsuarioCertificadoDataService,
        public dialog: MatDialog,
        private notification: NotificationService,
        private router: Router,
        private ngZone: NgZone
    ) { }

    @Action(actions.Existe)
    public existeCertificado({ patchState }: StateContext<UsuarioCertificadoStateModel>) {

        return this.certificadoService.existe().pipe(
            tap(result => patchState({
                existeUsuario: result.data.usuario,
                existePadrao: result.data.padrao
            }))
        );
    }

    @Action(actions.Enviar)
    public enviarCertificado({ dispatch, patchState }: StateContext<UsuarioCertificadoStateModel>, { payload }: actions.Enviar) {
        const formData = new FormData();
        formData.append('arquivo', payload);
        return this.certificadoService.upload(formData)
            .pipe(
                map(event => {
                    switch (event.type) {
                        case HttpEventType.UploadProgress:
                            const progress = Math.round(event.loaded * 100 / event.total);
                            console.log("Progresso:", progress)
                            break;
                        case HttpEventType.Response:
                            return event.body;
                    }
                }),
                map((result) => {
                    dispatch(actions.Existe);
                } )
            );
    }

    @Action(actions.Validar)
    public validarCertificado({ patchState, getState }: StateContext<UsuarioCertificadoStateModel>, { payload }: actions.Validar) {
        return this.certificadoService.validar(payload).pipe(
            map(({ data, sucesso }) => {
                if (sucesso) {
                    let cpf = '';
                    let nome = '';

                    const nomeCpf = new RegExp(/([^\d+]+)([\d+]+)/).exec(data.nomeCertificado);
                    if (nomeCpf?.length > 2) {
                        nome = nomeCpf[1].replace(":", "");
                        cpf = nomeCpf[2];
                    }
                    const dataVencimento = new Date(data.dataVencimento);
                    patchState({
                        nomeCertificado: nome || data.nomeCertificado,
                        cpf,
                        dataVencimento
                    });
                    this.dialog.getDialogById(getState().modalSenha).close();
                    return patchState({ modalSenha: null });
                } else {
                    this.notification.openError('Não foi possível validar o certificado.');
                }
            })
        );
    }

    @Action(actions.AbrirModalSenha)
    public abrirModalSenhaCertificado({ patchState, getState, dispatch }: StateContext<UsuarioCertificadoStateModel>, { logar, solicitarSenha }: actions.AbrirModalSenha) {
        const state = getState();
        
        if (state.existeUsuario || state.existePadrao) {
            this.ngZone.run(() => {
                const modal = this.dialog.open(UsuarioCertificadoModalSenhaComponent, {
                    id: `modal-certificado`,
                    disableClose: true,
                    data: { logar, solicitarSenha, certificadoPadrao: state.existePadrao, certificadoUsuario: state.existeUsuario },
                    width: '300px'
                } as MatDialogConfig);
                patchState({ modalSenha: modal.id });
            });
            return this.dialog.getDialogById(getState().modalSenha).afterClosed();
        }

        return this.ngZone.run(() => {
            this.notification.openSnackBarWithDispact(
                "Você não possui um certificado configurado, deseja ir para configurações?",
                "Sim", () => this.router.navigate(['usuario', 'configuracao']))
        });
    }

    @Action(actions.FecharModalSenha)
    public fecharModalSenhaCertificado({ getState, patchState, dispatch }: StateContext<UsuarioCertificadoStateModel>, { payload }: actions.FecharModalSenha) {
        patchState({ tipoCertificadoSelecionado: payload.tipoCertificado, modalSenhaConfirmado: payload.validar === true || payload.logar === true });
        const state = getState();
        
        if (payload.tipoCertificado == EnumTipoCertificadoEnvioPortalUnico.Usuario && state.existeUsuario && payload.logar) {
            const dadosPortalUnico = LocalStorageUtil.obterDadosPortalUnico();
            const tokenValido = dadosPortalUnico.accessToken && Date.parse(dadosPortalUnico.xCSRFExpiracao) > Date.now();
            if(!tokenValido){
                return dispatch(new actions.Logar({ certificadoPadrao: false, senha: payload.senha}));
            }
        }
        else if (payload.validar && !payload.logar) {
            return dispatch(new actions.Validar(payload.senha));
        }
        this.dialog.getDialogById(state.modalSenha).close();
        return patchState({ modalSenha: null });
    }

    @Action(actions.AbrirModalRemover)
    public abrirModalRemover({ patchState }: StateContext<UsuarioCertificadoStateModel>) {
        const modal = this.dialog.open(UsuarioCertificadoModalRemoverComponent, {
            id: `modal-remover`,
            disableClose: true,
        } as MatDialogConfig);
        patchState({
            modalRemover: modal.id
        });
    }

    @Action(actions.FecharModalRemover)
    public fecharModalRemover(
        { getState, dispatch }: StateContext<UsuarioCertificadoStateModel>,
        { payload }: actions.FecharModalRemover) {
        const state = getState();
        if (payload.confirmacao) {
            dispatch(new actions.Remover());
        }
        this.dialog.getDialogById(state.modalRemover).close();
    }

    @Action(actions.Remover)
    public remover({ patchState }: StateContext<UsuarioCertificadoStateModel>) {
        return this.certificadoService.remove('').pipe(
            map(x => patchState({ existeUsuario: false, existePadrao: false }))
        );
    }

    @Action(actions.Logar)
    public logar({ patchState, getState }: StateContext<UsuarioCertificadoStateModel>, { payload }: actions.Logar) {
        return this.certificadoService.autenticar(payload.certificadoPadrao, payload.senha)
            .pipe(tap(() => {
                const modal = this.dialog.getDialogById(getState().modalSenha);
                if (modal) {
                    modal.close();
                }
                patchState({ modalSenha: null });
            }));
    }
}
