import { BaseField } from './fields/base-field';

export class ConfigForm<T = any> {
  constructor(
    public fields: BaseField<T>[]
  ) { }

  getByProperty (prop: string){
      return  this.fields.find(x => x.property === prop)
  };

}
