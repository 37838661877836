import {FormControl, FormGroup} from '@angular/forms';
import {FocusMonitor} from '@angular/cdk/a11y';
import {BaseField} from './base-field';
import {AfterViewInit, Directive, ElementRef, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject, Subscription} from 'rxjs';

@Directive()
export abstract class BaseFieldDirective implements OnInit, AfterViewInit, OnDestroy {

    static nextId = 0;
    subscriptions: Subscription[] = [];
    @HostBinding() id = `input-${BaseFieldDirective.nextId++}`;
    focused = false;
    @Input() config!: BaseField;
    @Input() item: any = {};
    @Input() formGroup!: FormGroup;

    protected constructor(protected fm: FocusMonitor, protected el: ElementRef<HTMLElement>) {
        this.subscriptions.push(fm.monitor(el, true).subscribe(origin => {
            this.focused = !!origin;
            this.stateChanged.next();
            (this.formGroup!.get(this.config!.property) as FormControl).updateValueAndValidity();
        }));
    }

    public get controle(): FormControl {
        return this.formGroup!.get(this.config!.property) as FormControl;
    }

    public get requerido(): boolean {
        return !!this.config?.validations?.find(x => x.name === 'required');
    }

    stateChanged = new Subject<void>();

    ngAfterViewInit(): void {
    }

    ngOnInit(): void {
        this.config!.id = this.id;
        let value = this.config!.disabled instanceof Function ? this.config!.disabled(this.item) : this.config!.disabled;
        if (value) {
            this.controle.disable();
        }

        value = this.config!.esconder instanceof Function ? this.config!.esconder(this.item) : this.config!.esconder;
        if (value) {
            this.config!.css = 'd-none';
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(x => x.unsubscribe());
    }
}
