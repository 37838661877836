import { FocusMonitor } from '@angular/cdk/a11y';
import {
  Component, ChangeDetectionStrategy, Input, ElementRef, ViewChild, ChangeDetectorRef
} from '@angular/core';
import { tap } from 'rxjs/operators';
import { BaseFieldDirective } from '../base-field.directive';
import { FileField } from './file-field';

@Component({
  selector: 'ui-file-field',
  templateUrl: './file-field.component.html',
  styleUrls: ['./file-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileFieldComponent extends BaseFieldDirective {
  @Input() config!: FileField;

  @ViewChild('fileInput', { static: true }) fileInput!: ElementRef<HTMLInputElement>;
  fileName = '';
  file: any;
  constructor(
    protected fmRef: FocusMonitor,
    protected elRef: ElementRef<HTMLElement>,
    protected changeDetection: ChangeDetectorRef
  ) {
    super(fmRef, elRef);

  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscriptions.push(this.controle.valueChanges.pipe(
      tap(value => {
        if (!value) {
          this.fileName = '';
          this.file = null;
          this.changeDetection.detectChanges();
        }
      })
    ).subscribe());
  }

  onFileChanged(event: any) {
    const [file] = event.target.files;
    if (!file) {
      this.controle.reset();
      return;
    }

    this.fileName = file.name;
    this.file = file;
    this.controle.patchValue(file);
  }

}
