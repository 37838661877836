import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Meta } from "../services";
import { Action, GridConfig } from './grid.model';
import getPortuguesePaginatorIntl from './portuguese-paginator-intl';

const acoes = 'Ações';

@Component({
    selector: 'ui-grid',
    templateUrl: './grid.component.html',
    styleUrls: ['./grid.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: MatPaginatorIntl, useValue: getPortuguesePaginatorIntl() }
    ]
})

export class GridComponent {
    @Input() config!: GridConfig;
    @Input() data?: any | never[] = [];
    @Input() meta?: Meta;
    @Input() selecionados: any[] = [];
    @Input() mostrarSelecionarTodos: boolean = false;
    @Input() mostrarSelecionarItem: boolean = false;
    @Input() mensagemSemDados: string = 'Nenhum registro encontrado!';
    @Input() pageSizeOptions: number[] = [5, 10, 20, 50, 100];
    @Input() limitarAlturaMaxima: boolean = false;

    @Output() metaChanged = new EventEmitter<Meta>();
    @Output() selecionarTodos = new EventEmitter<any>();
    @Output() selecionarItem = new EventEmitter<any>();

    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;

    public maxHeight: number;
    public winHeight: number;

    constructor() {
        this.calculoMaxheight(window.innerHeight);
        this.winHeight = window.innerHeight;
    }

    private calculoMaxheight(height: number) {
        let valor = (height - 200) * 0.8;
        this.maxHeight = valor;
    }

    setMaxheight(event: Event) {
        const winHeight = (event.target as Window).innerHeight;
        this.calculoMaxheight(winHeight);
        this.winHeight = winHeight;
    }

    todasOpcoesEscondidas = (item: any) => {
        if (this.config?.actions) {
            const quantidadeOpcoesEscondidas = this.config.actions.filter((action) => {
                if (action.esconder !== null && action.esconder !== undefined && action.esconder instanceof Function) {
                    return action.esconder(item);
                }
                return false;
            }).length;

            return this.config.actions.length === quantidadeOpcoesEscondidas;
        }
        return false;
    }

    get props(): any[] {
        const propriedades: any[] = [];

        if (this.mostrarSelecionarTodos || this.mostrarSelecionarItem) {
            propriedades.push('checkbox');
        }
        const props = this.config?.fields?.filter(x => !x.esconder)
            .map(x => x.label) || this.data && this.data[0] && Object.keys(this.data[0]);

        propriedades.push(...props);
        if (this.config.actions) {
            propriedades.push(acoes);
        }

        return propriedades;
    }

    getAcoesTitle = () => {
        let title = acoes;
        if (this.config.actionsTitle) {
            title = this.config.actionsTitle;
        } else if (this.config.actions?.length === 1) {
            title = this.config.actions[0].title;
        }

        return title;
    };

    getConfig = (column: any) => this.config.fields?.find(x => x.label === column || x.property === column);

    getValue = (item: any, prop: any): any => {
        if (!prop.includes(acoes)) {
            const config = this.getConfig(prop);
            const value = config?.value instanceof Function ? config.value(item) : item[config?.property || prop];
            return value;
        }
    };

    getClasses = (item: any, column: any): string => {
        const config = this.getConfig(column)!;
        let classes = config?.classes instanceof Function ? config.classes(item) : config.classes;
        if (this.hasTooltip(item, column)) {
            classes += " tooltip";
        }
        return classes;
    };

    handleMetaChanged = ({ pageIndex, pageSize }: PageEvent) => {
        const newMeta = { ...this.meta, qtdeRegistros: pageSize, pagina: pageIndex + 1 } as Meta;
        this.metaChanged.emit(newMeta);
    };

    sortBy = (event: Sort) => {
        const propriedade = this.getConfig(event.active)?.property;
        const novoMeta: Meta = { ...this.meta, ordenacao: propriedade, sentido: event.direction };
        this.metaChanged.emit(novoMeta);
    };

    emitirSelecionarItem(item: any, checked: boolean) {
        this.selecionados = checked ? [...this.selecionados || [], item] : [...this.selecionados.filter(x => x !== item)];
        this.selecionarItem.emit({ item, checked });
    }

    itemSelecionado = (item: any) => this.selecionados && this.selecionados?.find(x => item === x);

    todosSelecionados = () =>
        (this.data && this.data.length > 0 && this.selecionados && this.selecionados.length > 0) && this.data.length == this.selecionados.length;

    selecionarTodosHandler = (checked: any) => this.selecionarTodos.emit(checked);

    isAction = (column: any): boolean => column === acoes;

    getTitle = (item: any, column: any): string => {
        const config = this.getConfig(column);
        if (config?.title) {
            return config.title instanceof Function ? config.title(item) : config.title
        }
        return '';
    }

    hasTooltip = (item: any, column: any): boolean => {
        const config = this.getConfig(column);
        return config?.tooltip !== undefined && config?.tooltip !== null && this.getToolTip(item, column) !== '';
    }

    getToolTip = (item: any, column: any): string => {
        const config = this.getConfig(column);
        if (config?.tooltip) {
            return config.tooltip instanceof Function ? config.tooltip(item) : config.tooltip
        }
        return '';
    }

    getMask = (item: any, column: any): string => {
        const config = this.getConfig(column);
        if (config?.mascara) {
            return config.mascara instanceof Function ? config.mascara(item) : config.mascara
        }

        return '';
    }

    esconder = (item: any, action: Action) => action?.esconder instanceof Function ? action?.esconder(item) : false;

    desabilitar = (item: any, action: Action) => {
        return action?.desabilitar instanceof Function ? action?.desabilitar(item) : false;
    };

    getCorIcone = (item: any, action: Action): string => {
        return !this.esconder(item, action) ? action.corIcone : undefined;
    }

    getPermiteOrdenar = (column: any): boolean => {
        const config = this.getConfig(column);

        if (config?.permiteOrdenar !== undefined) {
            return config.permiteOrdenar instanceof Function ? config.permiteOrdenar() : config.permiteOrdenar
        }
        return true;
    }

    getCorLinha = (item: any): string => {
        if (this.config?.corLinha) {
            return this.config?.corLinha instanceof Function ? this.config?.corLinha(item) : this.config?.corLinha
        }
    }

    getCorFonte = (item: any): string => {
        if (this.config?.corFonte) {
            return this.config?.corFonte instanceof Function ? this.config?.corFonte(item) : this.config?.corFonte
        }
    }

    getMultiLinhas = (column: any): boolean => {
        const config = this.getConfig(column);
        return config?.multiLinhas instanceof Function ? config?.multiLinhas() : config.multiLinhas;
    }

}

