<mat-form-field
  appearance="outline"
  [formGroup]="formGroup"
  style="margin-bottom: -1.25em; width: 100%; position: relative;"
>
    <mat-label>{{config.label}}</mat-label>

    <mat-chip-list #chipList>
        <mat-chip *ngIf="selecionado">
            {{fileName}}
            <button
                mat-icon-button
                type="button"
                matSuffix
                (click)="limpar()"
                *ngIf="!controle.disabled">
                <mat-icon>clear</mat-icon>
            </button>
        </mat-chip>
        <input
            matInput
            readonly
            class="cursor-pointer"
            (click)="fileInput.click()"
        />
    </mat-chip-list>

    <mat-icon
        matSuffix *ngIf="fileName"
        class="cursor-pointer"
        (mouseenter)="atualizaSrcImg(); imageWrapper.style.display = 'block'"
        (mouseleave)="imageWrapper.style.display='none'"
        (click)="maximizaImagem()">
        {{config.sufixo}}
    </mat-icon>

    <input
        type="file"
        #fileInput
        id="uploadFile"
        (change)="onFileChanged($event)"
        [accept]="config.tiposAceitos"
        [hidden]="true"
    />
</mat-form-field>
<div #imageWrapper class="img-container">
  <img #image />
</div>
