import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {Observable} from 'rxjs/internal/Observable';
import {map, shareReplay} from 'rxjs/operators';
import {Link} from '../models';
import {MasterService} from './master.service';

@Component({
    selector: 'ui-master',
    templateUrl: './master.component.html',
    styleUrls: ['./master.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MasterComponent {

    @ViewChild('drawer', { static: true }) public drawer!: MatSidenav;

    link!: Link;

    public isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
        .pipe(
            map(result => result.matches),
            shareReplay()
        );

    constructor(
        public masterService: MasterService,
        private breakpointObserver: BreakpointObserver,
        private changeDetection: ChangeDetectorRef
    ) { }

    handleClick = (link: Link) => {
        this.drawer.close();
        this.link = link;
        this.changeDetection.markForCheck();
        this.changeDetection.detectChanges();
    };
}
