<form class="ml-auto" (ngSubmit)="submitEvent.emit(termo.value)">
  <mat-form-field appearance="outline" class="w-100">
    <mat-label>Pesquisar</mat-label>
    <input
      type="text"
      matInput
      [formControl]="termo"
      [placeholder]="placeHolder"
      #inputSearch
    />
    <button
      type="submit"
      hidden
      matSuffix
      mat-button
      (click)="$event.preventDefault()"
    ></button>
    <mat-icon matSuffix (click)="termo.setValue('')" class="pointer"
      >{{termo.value ? 'clear' : 'search'}}</mat-icon
    >
  </mat-form-field>
</form>
