import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ClienteEntity } from '@catalogo/models';
import { ConfiguracaoEntity } from '@catalogo/models/configuracao.entity';
import { ApiResult } from 'ui-shared';
import { OPERACAO_API_URL } from "@catalogo/features/compartilhado/const/constantes";

@Injectable({
    providedIn: 'root'
})
export class ConfiguracaoDataService{

    constructor(
        @Inject(OPERACAO_API_URL) private operacaoUrl: string,
        @Inject(HttpClient) private httpClient: HttpClient
    ) { }

    obterAmbiente() {
        const apiUrl = `${this.operacaoUrl}/v1/configuracao/ambiente`;
        return this.httpClient.get<ApiResult<ConfiguracaoEntity>>(apiUrl);
    }

    obterDadosCliente() {
        const apiUrl = `${this.operacaoUrl}/v1/configuracao/cliente`;
        return this.httpClient.get<ApiResult<ClienteEntity>>(apiUrl);
    }
    
    private readonly URL_CONTRATO_LOGOTIPO: string = '/v1/configuracao/contrato/logotipo';

    obterIconeDespachante() {
        const apiUrl = `${this.operacaoUrl}${this.URL_CONTRATO_LOGOTIPO}`;
        return this.httpClient.get<ApiResult<string>>(apiUrl);
    }

    atualizarIconeDespachante(record: FormData) {
        const apiUrl = `${this.operacaoUrl}${this.URL_CONTRATO_LOGOTIPO}`;
        return this.httpClient.put<ApiResult<boolean>>(apiUrl, record);
    }
}
