import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MasterComponent } from 'ui-shared';
import { CatalogoConfigAuthGuard } from './features/compartilhado/login/catalogo-config-auth-guard';
import { CatalogoOperacaoAuthGuard } from './features/compartilhado/login/catalogo-operacao-auth-guard';
import { LoginComponent } from './features/compartilhado/login/login.component';
import { PublicoComponent } from "./features/publico/publico.component";
import {
    PaginaNaoEncontradaComponent
} from "./features/compartilhado/pagina-nao-encontrada/pagina-nao-encontrada.component";

const routes: Routes = [
    {
        path: 'config',
        component: MasterComponent,
        canActivate: [CatalogoConfigAuthGuard],
        children: [
            {
                path: 'home',
                loadChildren: () => import('./features/configuracao/home-config/home-config.module')
                    .then(m => m.HomeConfigModule)
            },
            {
                path: 'parametros',
                loadChildren: () => import('./features/configuracao/parametro-configuracao/parametro-configuracao.module')
                    .then(m => m.ParametroConfiguracaoModule)
            },
            {
                path: 'parametro-valor',
                loadChildren: () => import('./features/configuracao/parametro-valor/parametro-valor.module')
                    .then(m => m.ParametroValorModule)
            },
            {
                path: 'clientes',
                loadChildren: () => import('./features/configuracao/cliente/cliente.module')
                    .then(m => m.ClientesModule)
            },
            {
                path: 'agencia-emissora-internacional',
                loadChildren: () => import('./features/configuracao/agencia-emissora-internacional/agencia-emissora-internacional.module')
                    .then(m => m.AgenciaEmissoraInternacionalModule)
            },
            {
                path: 'notificacoes',
                loadChildren: () => import('./features/configuracao/notificacao/notificacao.module')
                    .then(m => m.NotificacaoModule)
            },
            {
                path: 'pais',
                loadChildren: () => import('./features/configuracao/pais/pais.module')
                    .then(m => m.PaisModule)
            },
            {
                path: 'solicitacao-processamento',
                loadChildren: () => import('./features/configuracao/solicitacao-processamento/solicitacao-processamento.module')
                    .then(m => m.SolicitacaoProcessamentoModule)
            },
            { path: '**', redirectTo: 'home' }
        ]
    },
    {
        path: '',
        component: MasterComponent,
        canActivate: [CatalogoOperacaoAuthGuard],
        children: [
            {
                path: 'home',
                loadChildren: () => import('./features/operacional/home/home.module')
                    .then(m => m.HomeModule)
            },
            {
                path: 'ncm',
                loadChildren: () => import('./features/compartilhado/ncm/ncm.module')
                    .then(m => m.NcmModule)
            },
            {
                path: 'atributo-receita',
                loadChildren: () => import('./features/compartilhado/atributo-receita/atributo-receita.module')
                    .then(m => m.AtributoReceitaModule)
            },
            {
                path: 'empresa',
                loadChildren: () => import('./features/operacional/empresa/empresa.module')
                    .then(m => m.EmpresaModule),
            },
            {
                path: 'parametro-operacao-valor',
                loadChildren: () => import('./features/operacional/parametro-operacao-valor/parametro-operacao-valor.module')
                    .then(m => m.ParametroOperacaoValorModule)
            },
            {
                path: 'mapeamento-atributos',
                loadChildren: () => import('./features/operacional/mapeamento-atributos/mapeamento-atributos.module')
                    .then(m => m.MapeamentoAtributosModule)
            },
            {
                path: 'gestao-usuario',
                loadChildren: () => import('./features/operacional/gestao-usuario/gestao-usuario.module')
                    .then(m => m.GestaoUsuarioModule)
            },
            {
                path: 'dados-cliente',
                loadChildren: () => import('./features/operacional/dados-cliente/dados-cliente.module')
                    .then(m => m.DadosClienteModule)
            },
            {
                path: 'fabricante-nacional',
                loadChildren: () => import('./features/operacional/fabricante-nacional/fabricante-nacional.module')
                    .then(m => m.FabricanteNacionalModule)
            },
            {
                path: 'operador-estrangeiro',
                loadChildren: () => import('./features/operacional/operador-estrangeiro/operador-estrangeiro.module')
                    .then(m => m.OperadorEstrangeiroModule)
            },
            {
                path: 'produto',
                loadChildren: () => import('./features/operacional/produto/produto.module')
                    .then(m => m.ProdutoModule)
            },
            {
                path: 'cliente-certificado',
                loadChildren: () => import('./features/operacional/cliente-certificado/cliente-certificado.module')
                    .then(m => m.ClienteCertificadoModule)
            },
            {
                path: 'tipo-descricao',
                loadChildren: () => import('./features/operacional/tipo-descricao/tipo-descricao.module')
                    .then(m => m.TipoDescricaoModule)
            },
            {
                path: 'pdm',
                loadChildren: () => import('./features/operacional/pdm/pdm.module')
                    .then(m => m.PdmModule)
            },
            {
                path: 'saneamento',
                loadChildren: () => import('./features/operacional/saneamento/saneamento.module')
                    .then(m => m.SaneamentoModule)
            },
            {
                path: 'atributos-modificados',
                loadChildren: () => import('./features/operacional/atributos-modificados/atributos-modificados.module')
                    .then(m => m.AtributosModificadosModule)
            },
            {
                path: 'catalogo',
                loadChildren: () => import('./features/operacional/catalogo/catalogo.module')
                    .then(m => m.CatalogoModule)
            },
            {
                path: 'usuario/configuracao',
                loadChildren: () => import('./features/operacional/usuario/configuracao/configuracao.module')
                    .then(m => m.ConfiguracaoModule)
            },
            {
                path: 'acesso-negado',
                loadChildren: () => import('./features/operacional/acesso-negado/acesso-negado.module')
                    .then(m => m.AcessoNegadoModule)
            },
            {
                path: 'historico-alteracoes',
                loadChildren: () => import('./features/operacional/historico-alteracoes/historico-alteracoes.module')
                    .then(m => m.HistoricoAlteracoesModule)
            },
            {
                path: 'importacao-dados',
                loadChildren: () => import('./features/operacional/importacao-dados/importacao-dados.module')
                    .then(m => m.ImportacaoDadosModule)
            },
            {
                path: 'relatorio',
                loadChildren: () => import('./features/operacional/relatorio/relatorio.module')
                    .then(m => m.RelatorioModule)
            },
            {
                path: 'unidade-negocio',
                loadChildren: () => import('./features/operacional/unidade-negocio/unidade-negocio.module')
                    .then(m => m.UnidadeNegocioModule)
            },
            {
                path: 'pendencias-envio',
                loadChildren: () => import('./features/operacional/pendencias-envio/pendencias-envio.module')
                    .then(m => m.PendenciasEnvioModule)
            }
        ]
    },
    {
        path: 'login',
        component: LoginComponent,
        pathMatch: 'full'
    },
    {
        path: 'publico',
        component: PublicoComponent,
        children: [
            {
                path: 'ncm',
                loadChildren: () => import('./features/publico/publico-ncm/publico-ncm.module')
                    .then(m => m.PublicoNcmModule)
            },
            {
                path: 'atributo-receita',
                loadChildren: () => import('./features/compartilhado/atributo-receita/atributo-receita.module')
                    .then(m => m.AtributoReceitaModule)
            },
            {
                path: 'integracao-doc',
                loadChildren: () => import('./features/publico/integracao-doc/integracao-doc.module')
                    .then(m => m.IntegracaoDocModule)
            },
            {
                path: '**',
                component: PaginaNaoEncontradaComponent
            }
        ]
    },
    {
        path: '**', redirectTo: '/login'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
