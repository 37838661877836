import {Component, Inject, OnInit, Renderer2} from '@angular/core';
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-publico',
  templateUrl: './publico.component.html',
  styleUrls: ['./publico.component.scss']
})
export class PublicoComponent implements OnInit {

  constructor(private _renderer2: Renderer2,
              @Inject(DOCUMENT) private _document: Document
  ) { }

  ngOnInit(): void {
      let script = this._renderer2.createElement('script');
      script.type = `text/javascript`;
      script.text = `
            window.addEventListener("load", function(){
                if(window.self === window.top)
                    return;

                const sendHeightToParentFunction = function () {
                    const height = document.getElementById("wrapper").clientHeight;
                    parent.postMessage({"height": height}, "*");
                };

                sendHeightToParentFunction();

                window.addEventListener("resize", sendHeightToParentFunction);

                const observer = new MutationObserver(sendHeightToParentFunction);

                observer.observe(window.document, {
                        attributes: true,
                        childList: true,
                        characterData: true,
                        subtree: true
                    }
                );
            });
        `;

      this._renderer2.appendChild(this._document.body, script);
  }

}
