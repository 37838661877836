export class FabConfig<T = any> {
    actions: FabAction<T>[] = [];

    constructor(init: FabConfig<T>) {
        Object.assign(this, init);
    }
}

export class FabAction<T = any> {
    title?: string = '';
    icone?: string = ''
    action!: (e: T) => void;
    corIcone?: string = '';
    esconder?: boolean = false;
    desabilitar?: boolean = false;
}
