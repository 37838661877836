<div [hidden]="link.hide">
    <ng-container *ngIf="link.links; else templateItens">
        <mat-accordion>
            <mat-expansion-panel
                class="mat-elevation-z"
                (opened)="panelOpenState = true"
                (closed)="panelOpenState = false"
            >
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <a mat-list-item>
                            <mat-icon mat-list-icon>{{link.icone}}</mat-icon>
                            <p mat-line>{{link.label }}</p>
                        </a>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container *ngFor="let linkFilho of link.links; last as last">
                    <ui-master-item
                        [link]="linkFilho"
                        (handleLinkClick)="handleLinkClick.emit(linkFilho); panelOpenState=false;"
                    ></ui-master-item>
                    <mat-divider *ngIf="!last"></mat-divider>
                </ng-container>
            </mat-expansion-panel>
        </mat-accordion>
    </ng-container>
    <ng-template #templateItens let-panelOpenState>
        <a
            mat-list-item
            [routerLink]="getRota(link)"
            [routerLinkActiveOptions]="{ exact: true}"
            routerLinkActive="active"
            [title]="link.label"
            (click)="handleLinkClick.emit(link); execRota(link)"
            [class.disabled]="link.disable"
        >
            <mat-icon mat-list-icon>{{link.icone}}</mat-icon>
            <p mat-line>{{link.label}}</p>
        </a>
    </ng-template>
</div>
