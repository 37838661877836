
export class ApiResult<T = any> {
  sucesso: boolean = false;
  data?: T;
  erros: Array<string> = [];
}

export class ListaPaginada<T = any> {
  total: number = 0;
  itens: Array<T> = [];
  pagina: number = 1;
  titulo?: string;
}

export class BaseErroResult {
  sucesso: boolean;
  erro: string;
  erros: string[];
}
