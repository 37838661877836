import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {NgxMaskModule} from 'ngx-mask';
import {AutoCompleteFieldComponent} from '../fields/auto-complete-field/auto-complete-field.component';
import {CheckboxFieldComponent} from '../fields/checkbox-field/checkbox-field.component';
import {DateFieldComponent} from '../fields/date-field/date-field.component';
import {EnumFieldComponent} from '../fields/enum-field/enum-field.component';
import {HiddenFieldComponent} from '../fields/hidden-field/hidden-field.component';
import {ImgDialogComponent} from '../fields/img-file-field/img-dialog/img-dialog.component';
import {ImgFileFieldComponent} from '../fields/img-file-field/img-file-field.component';
import {FileFieldComponent} from '../fields/file-field/file-field.component';
import {SelectFieldComponent} from '../fields/select-field/select-field.component';
import {TextFieldComponent} from '../fields/text-field/text-field.component';
import {FormFieldRendererComponent} from './form-field-renderer.component';
import {MatChipsModule} from "@angular/material/chips";
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    declarations: [
        FormFieldRendererComponent,
        TextFieldComponent,
        HiddenFieldComponent,
        CheckboxFieldComponent,
        EnumFieldComponent,
        FileFieldComponent,
        AutoCompleteFieldComponent,
        ImgFileFieldComponent,
        ImgDialogComponent,
        DateFieldComponent,
        SelectFieldComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        MatCheckboxModule,
        MatSelectModule,
        MatIconModule,
        MatAutocompleteModule,
        MatTooltipModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatDatepickerModule,
        MatNativeDateModule,
        NgxMaskModule.forChild(),
        MatChipsModule
    ],
    exports: [
        FormFieldRendererComponent
    ],
})
export class FormFieldRendererModule { }
