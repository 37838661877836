import {Injectable} from '@angular/core';
import * as actions from '../usuario-certificado-store/usuario-certificado.actions';
import {Store} from "@ngxs/store";
import { EnumTipoCertificadoEnvioPortalUnico } from '../../enums/enum-parametro-configuracao';

@Injectable({ providedIn: 'root' })
export class UsuarioCertificadoModalSenhaService {

    constructor(
        private store: Store
    ) { }

    public confirmar = (senha: string, logar: boolean, tipoCertificado: EnumTipoCertificadoEnvioPortalUnico) => {
        if(!tipoCertificado){
            tipoCertificado = EnumTipoCertificadoEnvioPortalUnico.Usuario;
        }
        this.store.dispatch(new actions.FecharModalSenha({validar: true, senha, logar, tipoCertificado}));
    };

    public fechar = () => this.store.dispatch(new actions.FecharModalSenha({ validar: false }));

}
