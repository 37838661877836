import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {ApiResult, IDENTIDADE_API_URL, OPERACAO_API_URL} from '../../services';
import {LoginResponse} from '../models';
import {ContaEmUsoModel} from './conta-em-uso.model';

@Injectable({
    providedIn: 'root'
})
//TODO Mover funcionalidades para módulo Operacional
export class ContaEmUsoDataService {

    constructor(
        @Inject(IDENTIDADE_API_URL) private identidadeUrl: string,
        @Inject(OPERACAO_API_URL) private operacaoUrl: string,
        @Inject(HttpClient) private httpClient: HttpClient
    ) { }

    obter(permissaoAmbiente: string) {
        const apiUrl = `${this.identidadeUrl}/v1/contas?permissaoAmbiente=${permissaoAmbiente}`;
        return this.httpClient.get<ApiResult<ContaEmUsoModel[]>>(apiUrl);
    }

    atualizarToken(contaId: any) {
        const apiUrl = `${this.identidadeUrl}/v1/autenticacao/trocarConta${contaId !== '-1' ? '?contaId=' + contaId : ''}`;
        return this.httpClient.get<ApiResult<LoginResponse>>(apiUrl);
    }

    obterPermissoes(email: string) {
        const apiUrl = `${this.operacaoUrl}/v1/usuario/email/${email}`;
        return this.httpClient.get<ApiResult>(apiUrl);
    }

    atualizarAcessoUsuario(email: string) {
        const apiUrl = `${this.operacaoUrl}/v1/usuario/${email}/ultimoacesso`;
        return this.httpClient.put<ApiResult>(apiUrl, null);
    }
}
