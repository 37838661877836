import { TipoNotificacao } from "../notificacao.component";

export class ObterPendentes {
  public static readonly type = '[Notificacao] Obter notificações pendentes';
}

export class Remover {
  public static readonly type = '[Notificacao] Remover notificações por tipo';
  constructor(public tipo: TipoNotificacao) { }
}
