import { BaseField } from "../base-field";

export class CheckboxField<T = any> extends BaseField<T> {

  constructor(init: Partial<CheckboxField<T>>) {
    super('Checkbox', init);
    if(!init.css){
      this.css = 'col-4'
    }
  }
}
