import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  public isLoading$: Observable<boolean>;

  loading: BehaviorSubject<boolean>;

  constructor() {
    this.loading = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.loading.asObservable();
  }

  public show() {
    this.loading.next(true);
  }
  public hide() {
    this.loading.next(false);
  }
}
