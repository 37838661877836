import {Inject, Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {UsuarioToken, UsuarioTokenDecoded, UsuarioAutenticado, UsuarioPermissoes} from '../master/models';
import {JwtHelperService} from './jwt-helper-service';
import {LocalStorageUtil} from './util/localstorage-util';

@Injectable({
    providedIn: 'root',
})
export class LoginService {

    public accessToken: string | null;
    public suporte: boolean = false;
    public token$: Observable<UsuarioToken | null>;
    private tokenBehaviorSubject: BehaviorSubject<UsuarioToken | null>;
    public contaEmUso: any;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private jwtHelper: JwtHelperService,
    ) {
        this.accessToken = LocalStorageUtil.obterTokenUsuario();
        const tokenDec = this.decode(this.accessToken!);

        this.tokenBehaviorSubject = new BehaviorSubject(tokenDec);

        this.token$ = this.tokenBehaviorSubject.asObservable();
    }

    public estaLogado = (): boolean => !!this.accessToken;

    public login = () => this.router.navigateByUrl('login');

    public logout() {
        LocalStorageUtil.limparDadosLocaisUsuario();
        this.tokenBehaviorSubject.next(null);
        this.accessToken = null;
        this.router.navigateByUrl('/login?logout=true');
    }

    public salvarToken = (accessToken: string) => {
        this.accessToken = accessToken;
        LocalStorageUtil.salvarTokenUsuario(accessToken);
        this.notificarNovoToken(accessToken);
    };

    public obterUsuario = (): UsuarioAutenticado =>
        JSON.parse(LocalStorageUtil.obterUsuario()!);

    public salvarUsuario = (usuarioAutenticado: UsuarioAutenticado) =>
        LocalStorageUtil.salvarUsuario(JSON.stringify(usuarioAutenticado));

    public salvarPermissoes = (permissoes: UsuarioPermissoes) =>
        LocalStorageUtil.salvarPermissoes(JSON.stringify(permissoes));

    public obterUsuarioPermissoes = (): UsuarioPermissoes =>
        JSON.parse(LocalStorageUtil.obterPermissoes()!);

    private notificarNovoToken = (token: string) => {
        const tokenDec = this.decode(token);
        if(tokenDec){
            this.tokenBehaviorSubject.next(tokenDec);
        }
    }

    private decode(accessToken: string): UsuarioToken | null {
        const tokenDecode = this.jwtHelper.decodeToken<UsuarioTokenDecoded>(accessToken);
        if (tokenDecode) {
            const { sub, usuario, suporte, contaEmUso, email } = tokenDecode;
            this.suporte = Boolean(suporte === 'True');
            this.contaEmUso = contaEmUso && JSON.parse(contaEmUso);
            return {
                id: sub, usuario, email,
                suporte: this.suporte,
                contaEmUso: this.contaEmUso,
            } as UsuarioToken;
        }
        return null;
    }
}
